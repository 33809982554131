<template>
  <v-app>
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 mb-2">
            <div
              class="
                d-flex
                justify-content-center
                align-items-center
                image-container
              "
            >
              <div style="height: 350px; width: 350px; position: relative">
                <!-- upload Preview -->
                <img
                  class="image"
                  :src="[
                    uploadedImageUrl == '' ? form.photo_name : uploadedImageUrl,
                  ]"
                  @load="onImageLoad()"
                  v-if="uploadMethod == 'upload'"
                />

                <!-- camera -->
                <div v-if="uploadMethod == 'camera'" class="mb-3">
                  <vue-web-cam
                    ref="webcam"
                    :device-id="deviceId"
                    width="100%"
                    @started="onStarted"
                    @stopped="onStopped"
                    @error="onError"
                    @cameras="onCameras"
                    @camera-change="onCameraChange"
                    v-if="captureStatus == false"
                  />

                  <figure class="figure" v-else-if="captureStatus == true">
                    <img
                      :src="baseImg"
                      class="img-responsive"
                      style="height: 350px; width: 350px"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 col-sm-12 mb-2">
            <div class="row">
              <div class="col-md-6">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  v-if="uploadMethod == 'camera' && captureStatus == false"
                  @click="onCapture"
                >
                  <i class="fas fa-camera"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-secondary w-100"
                  v-if="uploadMethod == 'upload'"
                >
                  <i class="fas fa-camera"></i>
                </button>
              </div>
              <div class="col-md-6">
                <b-button
                  text="Button"
                  variant="success"
                  @click="changeUploadBtn"
                  v-if="uploadMethod == 'camera'"
                  class="w-100"
                  >Kamera On</b-button
                >
                <b-button
                  text="Button"
                  variant="danger"
                  @click="changeUploadBtn"
                  v-if="uploadMethod == 'upload'"
                  class="w-100"
                  >Kamera Off</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md">
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <!-- Input Employee Number -->
          <b-form-group
            id="input-group-employee-numebr"
            label="Nomor Karyawan:"
            label-for="input-employee-numebr"
          >
            <b-form-input
              id="input-employee-number"
              v-model="form.employee_number_id"
              placeholder="Nomor karyawan"
            >
            </b-form-input>
            <small class="text-danger">{{ error.employee_number_id }}</small>
          </b-form-group>

          <!-- Input Name -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama Lengkap"
            >
            </b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <!-- Input Ktp Number -->
          <b-form-group id="input-group-ktp">
            <label for="input-ktp"
              >Nomor Ktp: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-ktp"
              v-model="form.id_card_number"
              placeholder="Nomor Ktp"
            >
            </b-form-input>
            <small class="text-danger">{{ error.id_card_number }}</small>
          </b-form-group>

          <!-- Input Email -->
          <b-form-group id="input-group-email">
            <label for="input-email">Email:</label>
            <b-form-input
              id="input-email"
              v-model="form.email"
              placeholder="Email"
            >
            </b-form-input>
            <small class="text-danger">{{ error.email }}</small>
          </b-form-group>

          <!-- Input Phone -->
          <b-form-group id="input-group-phone">
            <label for="input-phone"
              >Telepon: <em class="text-muted">opsional</em></label
            >
            <b-form-input
              id="input-phone"
              v-model="form.phone"
              type="number"
              placeholder="Telepon"
            >
            </b-form-input>
            <small class="text-danger">{{ error.phone }}</small>
          </b-form-group>

          <!-- Input Mobile Phone -->
          <b-form-group
            id="input-group-mobile-phone"
            label="Telepon Seluler: "
            label-for="input-mobile-phone"
          >
            <b-form-input
              id="input-mobile-phone"
              v-model="form.mobile_phone"
              placeholder="Telepon Seluler"
            ></b-form-input>
            <small class="text-danger">{{ error.mobile_phone }}</small>
          </b-form-group>

          <!-- Input Date of Birth -->
          <b-form-group
            id="input-group-birth-date"
            label="Tempat & Tanggal Lahir:"
            label-for="input-sip-birth-date"
          >
            <b-input-group>
              <div class="row">
                <div class="col-md-6">
                  <b-form-input
                    id="input-birth-place"
                    aria-label="Tempat Lahir"
                    placeholder="Tempat Lahir (opsional)"
                    v-model="form.birt_place"
                  ></b-form-input>
                </div>
                <div class="col-md-6">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="form.date"
                    persistent
                    width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-input-group>
                        <b-input-group-prepend>
                          <button
                            class="btn btn-secondary"
                            type="button"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fas fa-calendar"></i>
                          </button>
                        </b-input-group-prepend>
                        <b-form-input
                          id="input-trigger-modal-patient"
                          v-model="form.birt_date"
                          placeholder="Tanggal Lahir (YYYY-MM-DD)"
                          @input="formaterDate"
                        >
                        </b-form-input>
                      </b-input-group>
                    </template>

                    <v-date-picker
                      v-if="modal2"
                      v-model="form.birt_date"
                      locale="id"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.birt_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
            </b-input-group>
            <div class="row">
              <div class="col">
                <small class="text-danger">{{ error.birt_place }}</small>
              </div>
              <div class="col">
                <small class="text-danger">{{ error.birt_date }}</small>
              </div>
            </div>
          </b-form-group>

          <template v-if="password">
            <!-- Input Password -->
            <div class="form-group">
              <label for="password">Password:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div
                    class="input-group-text"
                    @click="
                      passwordIsVisible == 'password'
                        ? (passwordIsVisible = 'text')
                        : (passwordIsVisible = 'password')
                    "
                  >
                    <div v-if="passwordIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="passwordIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                  </div>
                </div>
                <input
                  :type="passwordIsVisible"
                  class="form-control"
                  placeholder="Password"
                  v-model="form.password"
                  id="input-password"
                />
              </div>
              <small class="text-danger">{{ error.password }}</small>
            </div>

            <!-- Input Password Confirmation -->
            <div class="form-group">
              <label for="password">Ulangi Password:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div
                    class="input-group-text"
                    @click="
                      confirmPasswordIsVisible == 'password'
                        ? (confirmPasswordIsVisible = 'text')
                        : (confirmPasswordIsVisible = 'password')
                    "
                  >
                    <div v-if="confirmPasswordIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="confirmPasswordIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                  </div>
                </div>
                <input
                  :type="confirmPasswordIsVisible"
                  class="form-control"
                  placeholder="Ulangi Password"
                  v-model="form.password_confirmation"
                  id="input-password-confirmation"
                />
              </div>
              <small class="text-danger">{{
                error.password_confirmation
              }}</small>
            </div>
          </template>

          <!-- position -->
          <b-form-group
            id="input-group-position"
            label="Jabatan :"
            label-for="input-position"
          >
            <b-form-select
              id="input-position"
              v-model="form.position_id"
              :options="positions"
            ></b-form-select>
            <small class="text-danger">{{ error.position_id }}</small>
          </b-form-group>

          <!-- department -->
          <b-form-group
            id="input-group-department"
            label="Departemen :"
            label-for="input-department"
          >
            <b-form-select
              id="input-department"
              v-model="form.department_id"
              :options="departments"
            ></b-form-select>
            <small class="text-danger">{{ error.department_id }}</small>
          </b-form-group>

          <!-- Input Address -->
          <b-form-group id="input-group-address">
            <label for="input-address"
              >Alamat: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-address"
              v-model="form.address"
              placeholder="Alamat Lengkap"
              rows="4"
              max-rows="6"
            ></b-form-textarea>
            <small class="text-danger">{{ error.address }}</small>
          </b-form-group>

          <!-- Profile Image -->
          <b-form-group id="input-group-image">
            <label for="input-image"
              >Foto Profil: <em class="text-muted">opsional</em></label
            >
            <b-form-file
              v-model="form.image"
              placeholder="Upload Gambar"
              drop-placeholder="Drop file here..."
              @change="onFileUpload"
            ></b-form-file>
          </b-form-group>

          <b-button variant="primary" type="submit">Simpan</b-button>
          <b-button
            class="ml-2"
            variant="default"
            @click="$router.push({ path: '/human-resource/employee/list' })"
          >
            Batal
          </b-button>
        </b-form>
      </div>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { WebCam } from "vue-web-cam";

export default {
  name: "Form",

  props: {
    form: Object,
    route: String,
    password: Boolean,
  },

  components: {
    "vue-web-cam": WebCam,
  },

  data() {
    return {
      imgLoaded: false,
      error: {
        email: "",
        name: "",
        birt_place: "",
        birt_date: "",
        address: "",
        phone: "",
        mobile_phone: "",
        id_card_number: "",
        employee_number_id: "",
        password: "",
        password_confirmation: "",
        position_id: "",
        depertment_id: "",
        photo: [],
      },
      positions: [],
      departments: [],
      imgLoaded: false,
      uploadedImageUrl: "",
      uploadMethod: "upload",
      // camera
      baseImg: null,
      camera: null,
      deviceId: null,
      devices: [],
      img: null,
      captureStatus: false,
      // other
      datePickerPopUp: false,
      passwordIsVisible: "password",
      confirmPasswordIsVisible: "password",

       // date
      time: null,
      menu2: false,
      modal2: false,
      picker: new Date().toISOString().substr(0, 10),
    };
  },

  methods: {
     formaterDate(val) {
      if (val.length == 4) {
        this.form.birt_date = this.form.birt_date + "-";
      }
      if (val.length == 7) {
        this.form.birt_date = this.form.birt_date + "-";
      }
    },

    onImageLoad() {
      this.imgLoaded = true;
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.photo = evt.target.files[0];
    },

    oncancel() {
      if (this.purpose == "master") {
        this.onStop();
        $router.push("/patient/list");
      } else if (this.purpose == "modal") {
        this.onStop();
        $bvModal.hide("modal-patient");
      }
    },

    changeUploadBtn() {
      if (this.uploadMethod == "upload") {
        this.uploadMethod = "camera";
        setTimeout(() => {
          this.onStart();
        }, 4000);
      } else {
        this.uploadMethod = "upload";
      }
    },

    async getData() {
      let position = await module.setSelectOption("positions");
      let department = await module.setSelectOption("departemens");
      this.positions = position.data;
      this.positions.unshift({
        text: "Pilih Jabatan",
        value: "",
        disabled: true,
      });
      this.departments = department.data;
      this.departments.unshift({
        text: "Pilih Departemen",
        value: "",
        disabled: true,
      });
    },

    async formOnSubmit() {
      let formData = new FormData();
      if (this.img != null) {
        this.form.photo = this.img;
      }
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/human-resource/employee/list");
      }
    },

    // camera method
    onCapture() {
      this.baseImg = this.$refs.webcam.capture();
      this.img = this.dataURItoBlob(this.baseImg);
      this.captureStatus = true;
      this.onStop();
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onPause() {
      this.$refs.webcam.pause();
    },
    onResume() {
      this.$refs.webcam.resume();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },

    // convert base64 to image
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString,
      });
    },
  },

  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },

  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>