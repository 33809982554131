<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Patient Select -->
      <!-- <b-form-group
        id="input-group-employee"
        label="Pilih Karyawan:"
        label-for="input-employee"
      >
        <treeselect
          v-model="form.employee_id"
          :multiple="false"
          :options="employee"
        />
        <small class="text-danger">{{ error.employee_id }}</small>
      </b-form-group> -->

      <b-form-group
          id="input-group-trigger-modal-employee"
          label="Karyawan :"
          label-for="input-trigger-modal-employee"
        >
          <b-form-input
            id="input-trigger-modal-employee"
            v-model="display.employee_name"
            placeholder="Pilih karyawan"
            readonly
            @click="$bvModal.show('modal-employee')"
            
          >
          </b-form-input>
          <small class="text-danger">{{ error.employee_id }}</small>
        </b-form-group>


      <!-- Remurenation type Select -->
      <b-form-group
        id="input-group-remuneration-type"
        label="Pilih Tipe Remunerasi:"
        label-for="input-remuneration-type"
      >
        <treeselect
          v-model="form.remuneration_type_id"
          :multiple="false"
          :options="remunerationType"
          @select="handleType"
        />
        <small class="text-danger">{{ error.remuneration_type_id }}</small>
      </b-form-group>

      <!-- amount -->
      <b-form-group
        id="input-group-amount"
        label="Jumlah (Rp):"
        label-for="input-amount"
        v-if="remunerationCategory == true"
      >
        <b-form-input
          id="input-amount"
          v-model="formatedAmount"
          placeholder="Jumlah.."
          
        ></b-form-input>
        <small class="text-danger">{{ error.ammount }}</small>
      </b-form-group>

      <!-- Tax amount -->
      <b-form-group
        id="input-group-amount"
        label="Jumlah (%):"
        label-for="input-amount"
        v-if="remunerationCategory == false"
      >
        <!-- percent -->
        <b-form-input
          id="input-amount"
          v-model="form.ammount"
          placeholder="Jumlah(%).."
          @keyup="checkPercent"
        ></b-form-input>
        <small class="text-danger">{{ error.ammount }}</small>
      </b-form-group>


      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/remunerations')"
      >
        Batal
      </b-button>
    </b-form>
    <ModalEmployee
      @chosenEmployee="setEmployee"
      @submitedEmployee="setEmployee"
    />
  </div>
</template>

<script>
import ModalEmployee from '@/component/general/ModalEmployee.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import moment from "moment";

export default {
  props: {
    form: Object,
    route: String,
  },

  components: {
    ModalEmployee,
    Treeselect,
  },

  data() {
    return {
      // data
      employee: "",
      remunerationType: "",
      remunerationCategory:null,
      // options
      // options: [
      //   { text: 'Nominal', value: 'nominal' },
      //   { text: 'Persen', value: 'percent' },
      // ],
      selected: 'nominal',
      // Error
      error: {
        employee: "",
        remuneration_type_id: "",
        ammount: "",
      },
      // display
      display:{
        employee_name:"",
        payment_method:""
      },
    };
  },

  methods: {
    numberFormat(){
      this.form.ammount = parseInt(this.form.ammount).toLocaleString('id-ID')
    },

    checkPercent(){
      if(this.form.ammount > 100){
        Swal.fire("Perhatian", "Nilai tidak boleh melebihi 100%", "warning");
        this.form.ammount = 0
      }
    },

    setEmployee(value) {
      this.form.employee_id = value.id
      this.display.employee_name = value.name
      this.$bvModal.hide('modal-employee')

    },

    getDisplayData(){
      console.log(this.form)
      if(this.form.employee_id != ""){
        this.display.employee_name = this.form.employee_name
        this.form.ammount = parseInt(this.form.ammount) 
        if(this.form.ammount <= 100){
           this.remunerationCategory = false
        }else{
           this.remunerationCategory = true
        }
      }

    },

    // async getEmployeesOption() {
    //   let response = await module.setTreeSelect("employees");
    //   // Success
    //   if (response.state == "success") {
    //     // Set Option Select
    //     this.employee = response.data;
    //     this.employee.unshift({
    //       label: "Pilih Karyawan",
    //       id: "",
    //       isDisabled: true,
    //     });
    //   }
    // },

    async handleType(evt){
      let found = this.remunerationType.findIndex(x => x.id == evt.id)
      console.log(evt.id);
      console.log(found);
      // let response = await module.get('remuneration-types/'+ found)
      let response = await module.get('remuneration-types/'+ evt.id)
      console.log(response);
      if(response.is_tax == 0){
        this.remunerationCategory = true
        this.form.is_tax = 0
      }else if(response.is_tax == 1){
        this.remunerationCategory = true
        this.form.is_tax = 1
      }else{
        this.remunerationCategory = false
        this.form.is_tax = 2
      }

    },

    async getRemuneratioTypesOption() {
      let response = await module.setTreeSelect("remuneration-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.remunerationType = response.data;
        this.remunerationType.unshift({
          label: "Pilih Tipe Remunerasi",
          id: "",
          isDisabled: true,
        });
      }
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.remunerationCategory = null
        this.$router.push("/masters/remunerations");
      }
    },
  },

  computed:{
    formatedAmount: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.ammount = newValue.toLocaleString('id-ID')
        } else {
          this.form.ammount = 0
        }
      },
      get() {
        return this.form.ammount
      }
    },
  },

  mounted() {
    // this.getEmployeesOption();
    this.getRemuneratioTypesOption();
    this.getDisplayData()
  },
};
</script>

<style>
</style>