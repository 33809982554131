var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end mt-n3"},[_c('div',{staticClass:"col-md-3"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama / NIP Karyawan"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1),(_vm.purpose !== 'modal-nurse' && _vm.purpose !== 'modal-omploop' && _vm.purpose !== 'modal-nurse-action')?_c('div',{staticClass:"col-md-3"},[_c('b-input-group',[_c('treeselect',{attrs:{"multiple":false,"options":_vm.departments},on:{"input":_vm.filterByDepartment},model:{value:(_vm.filter.department_id),callback:function ($$v) {_vm.$set(_vm.filter, "department_id", $$v)},expression:"filter.department_id"}})],1)],1):_vm._e()]),_c('b-table',{staticClass:"mt-3",style:('white-space: nowrap'),attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"cell(photo)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center image-container"},[_c('div',{staticStyle:{"height":"50px","width":"50px","position":"relative"}},[_c('img',{staticClass:"image",attrs:{"src":data.item.photo}})])])]}},{key:"cell(actions)",fn:function(data){return [(_vm.purpose == 'master')?[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail","placement":"bottom"},on:{"click":function($event){return _vm.$router.push({
                path: '/human-resource/employee/detail/' + data.item.id,
              })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),(_vm.manipulateBtn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Ubah","placement":"bottom"},on:{"click":function($event){return _vm.$router.push({
                path: '/human-resource/employee/edit/' + data.item.id,
              })}}},[_c('i',{staticClass:"fas fa-edit px-0"})]):_vm._e(),(_vm.manipulateBtn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger",attrs:{"size":"sm","title":"Hapus","placement":"bottom"},on:{"click":function($event){return _vm.deleteData(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})]):_vm._e()]:_vm._e(),(_vm.purpose == 'modal')?[_c('b-button',{staticClass:"mr-1 btn-info",attrs:{"size":"sm"},on:{"click":function($event){_vm.chooseEmployee({
                id: data.item.id,
                name: data.item.name + ` (${data.item.phone})`,
              })}}},[_vm._v(" Pilih ")])]:_vm._e(),(_vm.purpose == 'modal-nurse' && !_vm.checkbox || _vm.purpose == 'modal-omploop' && !_vm.checkbox || _vm.purpose == 'modal-nurse-action' && !_vm.checkbox)?[_c('b-button',{staticClass:"mr-1 btn-info",attrs:{"size":"sm"},on:{"click":function($event){return _vm.chooseEmployee({ id: data.item.id, name: data.item.name, position: data.item.position_name })}}},[_vm._v(" Pilih ")])]:_vm._e(),(_vm.purpose == 'modal-nurse' && _vm.checkbox || _vm.purpose == 'modal-omploop' && _vm.checkbox || _vm.purpose == 'modal-nurse-action' && _vm.checkbox)?[_c('b-form-checkbox',{attrs:{"checked":data.item.checked,"id":`checkbox-pick-item-${data.item.id}`,"name":`checkbox-pick-item-${data.item.id}`},on:{"change":function($event){return _vm.chooseEmployee({ id: data.item.id, name: data.item.name, position: data.item.position_name })}}})]:_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }