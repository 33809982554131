<template>
  <div>

    <b-modal
      id="modal-employee"
      size="xl"
      title="Pilih Karyawan"
      hide-footer
    >
      <!-- Tabs -->
      <b-tabs>
        <b-tab
          title="Daftar Karyawan"
          active
        >
          <div class="mt-4">
            <Table
              purpose="modal"
              @chooseEmployee="chosenEmployee"
            />
          </div>
        </b-tab>
        <b-tab title="Tambah Baru">
          <div class="mt-4">
             <Form :form="form" :route="'employees'" :password="true"/>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>

import Form from '@/component/human-resource/Form.vue'
import Table from '@/component/human-resource/Table.vue'

export default {

  components: {
    Form,
    Table
  },

  methods: {

    chosenEmployee(value) {
      this.$emit('chosenEmployee', value)
    },

    submitedEmployee(value) {
      this.$emit('submitedEmployee', value)
    }

  },

  data() {
    return {
       form:{
        email: "",
        name: "",
        birt_place: "",
        birt_date: "",
        address: "",
        phone: "",
        mobile_phone: "",
        id_card_number: "",
        password: "",
        password_confirmation: "",
        position_id:"",
        depertment_id:"",
        photo: [],
        photo_name: "/images/default-profile.svg"
      }
    }
  }

}
</script>

<style>
</style>